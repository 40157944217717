import React, { Component } from 'react';
import parse from 'html-react-parser';
import './styles/CustomStyles.css'


class SearchResult extends Component {
  constructor(props) {
    super(props)
  }
  getText(result) {
    var source = result._source;
    if (result.highlight && result.highlight.text)
      return parse(result.highlight.text[0]);
    else if (source.text && source.text.length > 0)
      source.text;
    else
      source.title;
  }

  render() {
    const { result } = this.props;
    const source = result._source;
    const highlight_text = this.getText(result)
    return (
      <div className="appeal">
      <h3><a target="_blank" href={source.url}>{source.title}</a></h3>
      <p>{highlight_text}</p>
      </div>);
  }
}
export default SearchResult;