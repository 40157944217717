import { format, set } from 'date-fns';
import 'isomorphic-fetch';
import React from 'react';
import Pagination from 'react-js-pagination';
import DatePic from './DatePic';
import SearchResult from './SearchResult';
import Department from "./Department";
import DocumentType from './DocumentType';
import Spinner from './Spinner';

class HomeIndex extends React.Component {
  constructor(props) {
    super(props)  
    this.state = {
      documentType: "decision",
      departments: [],
      term: "",
      startDate: "",
      endDate: "",
      searchResults: null,
      error:null,
      fetchInProgress: false,
      activePage: 1
    }
  }

  handleSearctermChange = event => {
    this.setState({term: event.target.value});
  }

  handleStartDateChange = date => {
    this.setState({startDate: date});
  }

  handleEndDateChange= date => {
    this.setState({endDate: date});
  }

  handelDocumentTypeChange = event => {
    if (event.target.value == "resource") {
      console.log('removing ecab');
      //Remove ecab when document type is decision 
      var ndepartments = this.state.departments.slice();
      ndepartments.splice(ndepartments.indexOf("ecab"), 1);
      console.log('after splice ',ndepartments);
      this.setState({departments: ndepartments}, () => {
        console.log('removed ecab',this.state.departments);
      });
    }
    this.setState({ documentType: event.target.value });
    console.log('after removing ecab',this.state.departments);
  }

  handelDepartmentChange = d => {
    console.log(`handelDepartmentchange`, d);
    this.setState({departments: d});
  }

  handleSearch = event => {
    var sdate = "";
    var edate = "";
    this.setState({ fetchInProgress: true });
    if(this.state.startDate) sdate = format(this.state.startDate, 'MM-dd-yyyy');
    if(this.state.endDate) edate = format(this.state.endDate, 'MM-dd-yyyy');
    fetch("/search?search[document_type]="+this.state.documentType+"&search[departments][]="+this.state.departments+"&search[term]="+this.state.term+"&search[start_date]="+sdate+"&search[end_date]="+edate+"&search[sort]=_score")
      .then((res) => {
        if (!res.ok) {
          throw Error(res.message)
        }
        return res.json()
      })
      .then((data) => {
        console.log('Data ', data);
        this.setState({
          searchResults: data.hits,
          fetchInProgress: false
        })
      })
      .catch(err => {
        this.state.error = err.message;
        this.setState({ fetchInProgress: false });
    })
    event.preventDefault();
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
    var sdate = "";
    var edate = "";
    if(this.state.startDate) sdate = format(this.state.startDate, 'MM-dd-yyyy');
    if(this.state.endDate) edate = format(this.state.endDate, 'MM-dd-yyyy');
    fetch("/search?search[document_type]="+this.state.documentType+"&search[departments][]="+this.state.departments+"&search[term]="+this.state.term+"&search[start_date]="+sdate+"&search[end_date]="+edate+"&search[sort]=_score&search[pageno]="+pageNumber)
      .then((res) => {
        if (!res.ok) {
          throw Error(res.message)
        }
        return res.json()
      })
      .then((data) => {
        console.log('Data ', data);
        this.setState({
          searchResults: data.hits,
        })
      })
      .catch(err => {
        this.state.error = err.message;
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <h1>Search DOL Appeals</h1>
          <p>To search DOL Appeals content, select the appropriate document type, case type, enter the search term, then click the search button.</p>
          { this.state.error && <div> {this.state.error} </div> }
          <div className="mb-2">
            <DocumentType onChange={this.handelDocumentTypeChange}/>
          </div>
          <div className="mb-2">
            <Department onChange={this.handelDepartmentChange} dtype={this.state.documentType} departments={this.state.departments}/>
          </div>
          <div className="mb-2">
              <DatePic onStartDateChange={this.handleStartDateChange} onEndDateChange={this.handleEndDateChange}/>
          </div>
          <div className="mb-2">
          <div className="small-8 medium-7 large-7 columns"><label htmlFor="search_term">Enter search term here</label></div>
            <div className="from-group form-row">
              <div className="col">
                <input maxLength="256" autoComplete="off" className="searchtext" size="256" type="text" onChange={this.handleSearctermChange} id="search_term"/>
              </div>
              <div className="col">
                <input type="submit" name="commit" value="SEARCH APPEALS" id="search_appeals" className="searchappeals-button" onClick={this.handleSearch}/>
              </div>
            </div>
          </div>
          {this.state.fetchInProgress && <Spinner></Spinner>} 
          <div className='row'>
            {this.state.searchResults && <h2>YOUR SEARCH RETURNED {this.state.searchResults.total} RESULTS</h2> }
          </div>
          <div className='row'>
            <div className="mb-2">
              {this.state.searchResults && this.state.searchResults.hits.length > 0 ? this.state.searchResults.hits.map(result => {
                  return <SearchResult result={result}></SearchResult> 
              }): "" } 
            </div>
          </div>
          <div className='row'>
            {this.state.searchResults && <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={25}
                totalItemsCount={this.state.searchResults.total}
                pageRangeDisplayed={6}
                onChange={this.handlePageChange.bind(this)}
              />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HomeIndex
