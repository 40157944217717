import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/CustomStyles.css'

class DatePic extends Component {

  constructor (props) {
    super(props)
    this.state = {
      start_date: null,
      end_date: null
    };
    this.onDateChange = this.onDateChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onDateChange(dateName, dateValue) {
    let {start_date, end_date} = this.state;
    if (dateName === 'startDate') {
      this.props.onStartDateChange(dateValue);
      console.log(dateValue);
      console.log(start_date);
      start_date = dateValue;
      console.log(start_date);
    } else {
      this.props.onEndDateChange(dateValue);
      console.log(dateValue);
      console.log(end_date);
      end_date = dateValue;
      console.log(end_date);
    }
    this.setState({
      start_date,
      end_date,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    console.log(this.state.start_date)
    console.log(this.state.end_date)
  }
 
  render() {
    return (
      <div className="form-group">
        <p ><strong>Note: </strong>Leave "Start Date" and "End Date" fields empty to search all records.</p>
        <p ><strong>Reminder: </strong>If you do not want to use the date picker when tabbing through the page, you can use the ESC key to close it</p>
        <form onSubmit={ this.onFormSubmit }>
          <div className="block_container">
          <label htmlFor="start-date">Start Date (mm-dd-yyyy)
              <div id="block1">
                  <DatePicker
                    id="start-date"
                    name="startDate"
                    className="form-control"
                    selected={ this.state.start_date }
                    value={this.state.start_date }
                    onChange={date => this.onDateChange('startDate', date)}
                    dateFormat="MM-dd-yyyy"
                  />
              </div>
          </label>
          <label htmlFor="end-date">End Date (mm-dd-yyyy)
              <div id="block2">
                  <DatePicker
                  id="end-date"
                  name="endDate"
                  className="form-control"
                  selected={ this.state.end_date }
                  value={this.state.end_date }
                  onChange={date => this.onDateChange('endDate', date)}
                  dateFormat="MM-dd-yyyy"
                  />
                </div>
          </label>
            </div> 
            </form>
        </div>
    );
  }
  
}

export default DatePic;
