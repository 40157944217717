import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MyImage from 'images/glyphicons-195-question-sign.png';



class Department extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departments: this.props.departments,
    };
    console.log("incude department ",this.state.departments);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    const target = event.target;  
    var new_departments = this.state.departments.slice();
    if (target.checked) {
      new_departments.push(target.value);
    } else {
      new_departments.splice(new_departments.indexOf(target.value), 1);
    }
    this.setState({ departments: new_departments}, () => {
      this.props.onChange(this.state.departments);
    });
  }


  render() {
    return (
      <div className="form-group">
          { this.props.dtype == "decision" && 
            <div className="form-inline">
              <input className="form-check-input" type="checkbox" value="ecab" id="ECAB" onChange={this.handleChange}/>
              <label className="form-check-label" htmlFor="ECAB"> Federal Employees' Workers Compensation (ECAB) </label>
              <button data-toggle="collapse" data-target="#collapseEcab" aria-expanded="false" aria-controls="collapseEcab" className="data-toggle">
              <img alt="ECAB Description" src={MyImage}/>
              </button>
              <div className="collapse" id="collapseEcab">
                <div className="card card-body mb-3">
                    <p>The Federal Employees' Compensation Act governs the claims of federal employees injured in the course of their employment. The Employees' Compensation Appeals Board ECAB) hears and decides cases on appeal from decisions of the Office of Workers' Compensation Programs (OWCP).</p>
                </div>
              </div>
            </div>
          }
          <div className="form-inline">
              <input className="form-check-input" type="checkbox" value="blacklung" id="BRB" onChange={this.handleChange}/>
              <label className="form-check-label" htmlFor="BRB"> Black Lung (BRB) </label>
              <button data-toggle="collapse" data-target="#collapseBrb" aria-expanded="false" aria-controls="collapseBrb" className="data-toggle">
                <img alt="Black Lung/BRB Description" src={MyImage}/>
              </button>
              <div className="collapse" id="collapseBrb">
                <div className="card card-body mb-3">
                  <p>The federal Black Lung benefits program is for coal miners who are totally disabled due to pneumoconiosis and surviving dependents of miners whose death was due to pneumoconiosis.</p>
                </div>
              </div>
          </div>
          <div className="form-inline">
            <input className="form-check-input" type="checkbox" value="longshore" id="Longshore" onChange={this.handleChange}/>
            <label className="form-check-label" htmlFor="Longshore"> Longshore (BRB) </label>
            <button data-toggle="collapse" data-target="#collapseLongshore" aria-expanded="false" aria-controls="collapseLongshore" className="data-toggle">
              <img alt="Longshore/BRB Description" src={MyImage}/>
            </button>
            <div className="collapse" id="collapseLongshore">
              <div className="card card-body mb-3">
                <p>The Longshore and Harbor Workers' Compensation Act provides medical benefits, compensation for lost wages and rehabilitation services to longshoremen, harbor workers and other maritime workers who are injured during the course of employment or suffer from diseases caused or worsened by conditions of employment. Several other statutes extend the provisions of the Act to cover other classes of private-industry workers. These include workers engaged in the extraction of natural resources of the outer continental shelf, employees on American defense bases, and those working under contracts with the U.S. government for defense or public-works projects, outside of the Continental United States.</p>
              </div>
            </div>
          </div>
      </div>
    );
  }
  
}

export default Department;
