import React, { Component } from 'react';
class DocumentType extends Component {
    constructor(props) {
      super(props);
      this.state = {
        document_type: null,
      };
  
      this.handleChange = this.handleChange.bind(this);
    }

      handleChange = (document_type) => {
        this.setState({ document_type });
      }
  
    render() {
      return (
          <div className="form-group">
              <label className="col" htmlFor="search_document_type"> Document Type </label>
              <select className="col form-select form-select-lg mb-3" aria-label="Default select Decision" onChange={this.props.onChange} id ="search_document_type">
                <option value="decision">Decision</option>
                <option value="resource">Resource</option>
              </select>
          </div>
      );
    }
  }
  
  export default DocumentType;